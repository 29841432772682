import React, { useState } from "react";
// import * as ramda from "ramda";
import sortBy from "ramda/es/sortBy";
import prop from "ramda/es/prop";
import { useMedia } from "use-media";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import Container from "../../components/chat-channel/Container";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { SearchIcon } from "../../components/common/Icons";
import ArrowRightICon from "../../components/common/Icons";
import Cards from "../../components/chat-channel/two-cards";
import { CardsThree } from "../homepage";
import KeyResources from "../../components/common/KeyResources";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";

export const blogImg = require("../../assets/img/key-resources/bc_1_3.png");
export const whitepaperImg = require("../../assets/img/key-resources/wp_1_3.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_3.png");

const topImage = require("../../assets/img/feature/automation_designer/chatbot_automation_sec_2.png");

const box = require("../../assets/images/icons-web/box_1.svg");
const dropbox = require("../../assets/images/icons-web/dropbox.svg");
const freshdesk = require("../../assets/images/icons-web/fd_1.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const gdrive = require("../../assets/images/icons-web/gdrive.svg");
const jira = require("../../assets/images/icons-web/jira.svg");
const jumpcloud = require("../../assets/images/icons-web/jumpcloud.svg");
const ad = require("../../assets/images/icons-web/zure.svg");
const office365 = require("../../assets/images/icons-web/ms_office.svg");
const okta = require("../../assets/images/icons-web/okta.svg");
const onedrive = require("../../assets/images/icons-web/onedrive.svg");
const outlook = require("../../assets/images/icons-web/outlook.svg");
const servicenow = require("../../assets/images/icons-web/servicenow.svg");
const sharepoint = require("../../assets/images/icons-web/sharepoint.svg");
const slack = require("../../assets/images/icons-web/slack.svg");
const solarwinds = require("../../assets/images/icons-web/solarwinds.svg");
const twilio = require("../../assets/images/icons-web/twilio.svg");
const zendesk = require("../../assets/images/icons-web/zd_1.svg");
const zenefits = require("../../assets/images/icons-web/zenefits.svg");
const authO = require("../../assets/images/icons-web/auth0.svg");
const bamboo = require("../../assets/images/icons-web/bamboo.svg");
const deputy = require("../../assets/images/icons-web/deputy.svg");
const icd = require("../../assets/images/icons-web/icd1.svg");
const onelogin = require("../../assets/images/icons-web/onelogin.svg");
const orangehrm = require("../../assets/images/icons-web/orangehrm.svg");
const peoplehr = require("../../assets/images/icons-web/people-hr.svg");
const purelyhr = require("../../assets/images/icons-web/purelyhr.svg");
const vonage = require("../../assets/images/icons-web/vonage.svg");
const zoho_people = require("../../assets/images/icons-web/zoho_people_1.svg");
const zoho_desk = require("../../assets/images/icons-web/zohodesk.svg");
const lastpass = require("../../assets/images/icons-web/lastpass.svg");
const otrs = require("../../assets/images/icons-web/otrs.svg");
const tsheets = require("../../assets/images/icons-web/tsheets.svg");
const service_desk = require("../../assets/images/icons-web/service_desk.svg");
const fingercheck = require("../../assets/images/icons-web/fingercheck.svg");
const halo = require("../../assets/images/icons-web/halo.svg");
const idaptive = require("../../assets/images/icons-web/idaptive.svg");
const jira_desk = require("../../assets/images/icons-web/jira-desk.svg");
const pingIdentity = require("../../assets/images/icons-web/pingIdentity.svg");
const gusto = require("../../assets/images/icons-web/gusto.svg");
const g_calendar = require("../../assets/images/icons-web/google-calendar.svg");
const intune = require("../../assets/images/icons-web/ms_intune.svg");
const aws = require("../../assets/images/icons-web/aws.svg");
const invgate = require("../../assets/images/icons-web/InvGate.svg");
const sf = require("../../assets/images/icons-web/salesforce_desk.svg");
const rsa_securid = require("../../assets/images/icons-web/rsa_securid.svg");
const fusion_auth = require("../../assets/images/icons-web/fusionauth.svg");
const duo = require("../../assets/images/icons-web/duo.svg");
const greythr = require("../../assets/images/icons-web/greythr.svg");
const cherwell = require("../../assets/images/icons-web/cherwell.svg");
const marval = require("../../assets/images/icons-web/marval.svg");
const sysaid = require("../../assets/images/icons-web/sysaid.svg");
const topdesk = require("../../assets/images/icons-web/topdesk.svg");
const ivanti = require("../../assets/images/icons-web/ivanti.svg");
const webhr = require("../../assets/images/icons-web/web_hr.svg");
const maas_360 = require("../../assets/images/icons-web/mass_360.svg");
const Jamf = require("../../assets/images/icons-web/jamf.svg");
const mobileiron = require("../../assets/images/icons-web/mobile_iron.svg");
const tripactions = require("../../assets/images/icons-web/trip_actions.svg");
const certify = require("../../assets/images/icons-web/certify.svg");
const zoho_expense = require("../../assets/images/icons-web/zoho_expense.svg");
const sap = require("../../assets/images/icons-web/sap_successfactors.svg");
const expensify = require("../../assets/images/icons-web/expensify.svg");
const rydoo = require("../../assets/images/icons-web/rydoo.svg");
const airwatch = require("../../assets/images/icons-web/airwatch.svg");
const hexnode = require("../../assets/images/icons-web/hexnode.svg");
const citrix = require("../../assets/images/icons-web/citrix.svg");
const addigy = require("../../assets/images/icons-web/Addigy.svg");
const confluence = require("../../assets/images/icons-web/Confluence.svg");
const telnyx = require("../../assets/images/icons-web/telnyx.svg");
const alloynav = require("../../assets/images/icons-web/Alloy_Navigator.svg");
const meamp = require("../../assets/images/icons-web/ms_ad.svg");
const hornbill = require("../../assets/images/icons-web/hornbill.svg");
const activecampaign = require("../../assets/images/icons-web/ActiveCampaign.svg");
const freshworkscrm = require("../../assets/images/icons-web/Freshworks_CRM.svg");
const hubspot = require("../../assets/images/icons-web/Hubspot_CRM.svg");
const insightly = require("../../assets/images/icons-web/Insightly.svg");
const microsoftdynamics = require("../../assets/images/icons-web/Microsoft_Dynamics.svg");
const pipedrive = require("../../assets/images/icons-web/Pipedrive.svg");
const salesforcecrm = require("../../assets/images/icons-web/salesforce_desk_CRM.svg");
const zohocrm = require("../../assets/images/icons-web/zoho_crm.svg");
const zendesksell = require("../../assets/images/icons-web/zendesk_sell.svg");
const notion = require("../../assets/images/icons-web/notion.svg");
const xero = require("../../assets/images/icons-web/xero.svg");
const halopsa = require("../../assets/images/icons-web/halopsa.svg");
const connectwise = require("../../assets/images/icons-web/connectwise_manage.svg");
const freshbooks = require("../../assets/images/icons-web/fresh-books.svg");
const netsuite = require("../../assets/images/icons-web/netsuite.svg");
const quickbooks = require("../../assets/images/icons-web/quickbooks.svg");
const sageintact = require("../../assets/images/icons-web/sage_intacct.svg");
const gmail = require("../../assets/images/icons-web/gmail.svg");
const jsm = require("../../assets/images/icons-web/jira_service_desk.svg");
const workday = require("../../assets/images/icons-web/workday.svg");

const integrationsPage = [
  {
    image: activecampaign,
    header: "ActiveCampaign",
    content: "Automate deal updates, customer management & more",
    link: "/conversational-ai-platform/activecampaign-chatbot",
    alt: "active campaign",
  },
  {
    image: hubspot,
    header: "Hubspot CRM",
    content: "Automate deal updates, customer management & more",
    link: "/conversational-ai-platform/hubspot-crm-chatbot",
    alt: "hubspot",
  },
  {
    image: insightly,
    header: "Insightly",
    content: "Automate deal updates, customer management & more",
    link: "/conversational-ai-platform/insightly-chatbot",
    alt: "insightly",
  },
  {
    image: notion,
    header: "Notion",
    content: "Automate issue management, asset request & more",
    link: "/conversational-ai-platform/notion-chatbot",
    alt: "notion",
  },
  {
    image: xero,
    header: "Xero",
    content: "Automate purchase order creation, contact & more",
    link: "/conversational-ai-platform/xero-chatbot",
    alt: "xero",
  },
  { image: freshbooks,
    header: "Freshbooks",
    link: "/conversational-ai-platform/freshbooks-chatbot",
    content: "Automate purchase order creation, contact & more",
    alt: "freshbooks",
    toDisplayNew: true,
  },

  { image: netsuite,
    header: "NetSuite",
    link: "/conversational-ai-platform/netsuite-chatbot",
    content: "Automate purchase order creation, contact & more",
    alt: "netSuite",
    toDisplayNew: true,
  },

  { image: quickbooks,
    header: "Quickbooks",
    link: "/conversational-ai-platform/quickbooks-chatbot",
    content: "Automate purchase order creation, contact & more",
    header: "quickbooks",
    toDisplayNew: true,
  },

  { image: sageintact,
    header: "Sage Intacct",
    link: "/conversational-ai-platform/sage-intacct-chatbot",
    content: "Automate purchase order creation, contact & more",
    alt: "Sage Intacct",
    toDisplayNew: true,
  },
  { image: connectwise,
    header: "ConnectWise",
    link: "/conversational-ai-platform/connectwise-chatbot",
    content: "Automate ticketing, user management, access provisioning & more",
    alt: "connectwise",
  },
  {
    image: halopsa,
    header: "HaloPSA",
    content: "Automate ticketing, user management, access provisioning & more",
    link: "/conversational-ai-platform/halopsa-chatbot",
    alt: "halopsa",
  },
  {
    image: freshworkscrm,
    header: "Freshworks CRM",
    content: "Automate deal updates, customer management & more",
    link: "/conversational-ai-platform/freshworks-crm-chatbot",
    alt: "freshworkscrm",
  },
  {
    image: pipedrive,
    header: "Pipedrive",
    content: "Automate deal updates, customer management & more",
    link: "/conversational-ai-platform/pipedrive-chatbot",
    alt: "pipedrive",
  },
  {
    image: salesforcecrm,
    header: "Salesforce CRM",
    content: "Automate deal updates, customer management & more",
    link: "/conversational-ai-platform/salesforce-crm-chatbot",
    alt: "salesforcecrm",
  },
  {
    image: microsoftdynamics,
    header: "Microsoft Dynamics",
    content: "Automate deal updates, customer management & more",
    link: "/conversational-ai-platform/microsoft-dynamics-chatbot",
    alt: "microsoftdynamics",
    toDisplayNew: true,
  },
  {
    image: zohocrm,
    header: "Zoho CRM",
    content: "Automate deal updates, customer management & more",
    link: "/conversational-ai-platform/zoho-crm-chatbot",
    alt: "zohocrm",
  },
  {
    image: zendesksell,
    header: "Zendesk Sell",
    content: "Automate deal updates, customer management & more",
    link: "/conversational-ai-platform/zendesk-sell-chatbot",
    alt: "zendesksell",
  },

  {
    image: citrix,
    header: "Citrix",
    content: "Automate device management and mobile apps.",
    link: "/conversational-ai-platform/citrix-chatbot",
    alt: "Citrix",
    toDisplayNew: true,
  },

  {
    image: hexnode,
    header: "Hexnode",
    content: "Automate device management and mobile apps.",
    link: "/conversational-ai-platform/hexnode-chatbot",
    alt: "Hexnode",
  },
  {
    image: airwatch,
    header: "Airwatch",
    content: "Automate device management and mobile apps.",
    link: "/conversational-ai-platform/airwatch-chatbot",
    alt: "airwatch",
    toDisplayNew: true,
  },
  {
    image: sap,
    header: "SAP SuccessFactors",
    content: "Automate onboarding, leave management and more.",
    link: "/conversational-ai-platform/sap-successfactors-chatbot",
    alt: "SAP SuccessFactors",
    toDisplayNew: true,
  },
  {
    image: service_desk,
    header: "ManageEngine MDM",
    content: "Automate device management and mobile apps.",
    link: "/conversational-ai-platform/manageengine-mdm-chatbot",
    alt: "ManageEngine MDM",
  },
  {
    image: expensify,
    header: "Expensify",
    content: "Automate expense management to meet your specific needs.",
    link: "/conversational-ai-platform/expensify-chatbot",
    alt: "Expensify",
  },
  {
    image: zoho_expense,
    header: "Zoho Expense",
    content: "Automate expense management to meet your specific needs.",
    link: "/conversational-ai-platform/zoho-expense-chatbot",
    alt: "Zoho Expense",
  },
  {
    image: rydoo,
    header: "Rydoo",
    content: "Automate expense management to meet your specific needs.",
    link: "/conversational-ai-platform/rydoo-chatbot",
    alt: "rydoo",
    toDisplayNew: true,
  },
  {
    image: certify,
    header: "Certify",
    content: "Automate expense management to meet your specific needs.",
    link: "/conversational-ai-platform/certify-chatbot",
    alt: "Certify",
    toDisplayNew: true,
  },
  {
    image: tripactions,
    header: "Tripactions",
    content: "Automate expense management to meet your specific needs.",
    link: "/conversational-ai-platform/tripactions-chatbot",
    alt: "Tripactions",
    toDisplayNew: true,
  },
  {
    image: mobileiron,
    header: "MobileIron",
    content: "Automate device management and mobile apps.",
    link: "/conversational-ai-platform/mobileiron-chatbot",
    alt: "MobileIron",
  },
  {
    image: Jamf,
    header: "Jamf",
    content: "Automate device management and mobile apps.",
    link: "/conversational-ai-platform/jamf-chatbot",
    alt: "Jamf",
  },
  {
    image: maas_360,
    header: "MaaS360",
    content: "Automate device management and mobile apps.",
    link: "/conversational-ai-platform/maas360-chatbot",
    alt: "MaaS360",
    toDisplayNew: true,
  },
  {
    image: webhr,
    header: "WebHR",
    content: "Automate onboarding, leave management and more.",
    link: "/conversational-ai-platform/webhr-chatbot",
    alt: "webhr",
    toDisplayNew: true,
  },
  {
    image: ivanti,
    header: "Ivanti",
    content: "Automate ticketing, requests, user management and more.",
    link: "/conversational-ai-platform/ivanti-chatbot",
    alt: "Ivanti",
  },
  {
    image: topdesk,
    header: "TOPdesk",
    content: "Automate ticketing, requests, user management and more.",
    link: "/conversational-ai-platform/topdesk-chatbot",
    alt: "Topdesk",
  },
  {
    image: sysaid,
    header: "SysAid",
    content: "Automate ticketing, requests, user management and more.",
    link: "/conversational-ai-platform/sysaid-chatbot",
    alt: "SysAid",
  },
  {
    image: service_desk,
    header: "ManageEngine AD Manager",
    content: "Automate access, user management and more.",
    link: " /conversational-ai-platform/manageengine-ad-chatbot",
    alt: "ManageEngine AD Manager",
    toDisplayNew: true,
  },
  {
    image: marval,
    header: "Marval",
    content: "Automate ticketing, requests, user management and more.",
    link: "/conversational-ai-platform/marval-chatbot",
    alt: "Marval",
  },
  {
    image: sf,
    header: "Salesforce service cloud",
    content: "Automate ticketing, requests, user management and more.",
    link: "/conversational-ai-platform/salesforce-service-cloud-chatbot",
    alt: "Salesforce service cloud",
  },
  {
    image: cherwell,
    header: "Cherwell",
    content: "Automate ticketing, contact management and more.",
    link: "/conversational-ai-platform/cherwell-chatbot",
    alt: "Cherwell",
  },
  {
    image: greythr,
    alt: "greythr",
    header: "GreytHR",
    content: "Automate onboarding, leave management and more.",
    link: "/conversational-ai-platform/greythr-chatbot",
    toDisplayNew: true,
  },
  {
    image: duo,
    alt: "Duo Security",
    header: "Duo Security",
    content: "Automate access, user management and more.",
    link: "/conversational-ai-platform/duo-security-chatbot",
  },
  {
    image: fusion_auth,
    alt: "FusionAuth",
    header: "FusionAuth",
    content: "Automate access, user management and more.",
    link: "/conversational-ai-platform/fusionauth-chatbot",
  },
  {
    image: rsa_securid,
    alt: "RSA SecurID",
    header: "RSA SecurID",
    content: "Automate access, user management and more",
    link: "/conversational-ai-platform/rsa-securid-chatbot",
    toDisplayNew: true,
  },
  {
    image: sf,
    alt: "Salesforce Identity",
    header: "Salesforce Identity",
    content: "Automate access, user management and more",
    link: " /conversational-ai-platform/salesforce-identity-chatbot",
    toDisplayNew: true,
  },
  {
    image: invgate,
    alt: "Invgate",
    header: "Invgate",
    content: "Automate ticketing, user management and more.",
    link: "/conversational-ai-platform/invgate-chatbot",
  },
  {
    image: aws,
    alt: "AWS (IAM)",
    header: "AWS (IAM)",
    content: "Automate access, user management and more.",
    link: "/conversational-ai-platform/aws-chatbot",
  },
  {
    image: meamp,
    header: "Manage Engine AD Manager Plus",
    content: "Automate access, user management and more.",
    link: "/conversational-ai-platform/manageengine-ad-manager-plus-chatbot",
    alt: "Manage Engine AD Manager Plus",
  },
  {
    image: intune,
    alt: "Microsoft Intune",
    header: "Microsoft Intune",
    content: "Automate device management and mobile apps",
    link: "/conversational-ai-platform/microsoft-intune-chatbot",
  },
  {
    image: addigy,
    header: "Addigy",
    content: "Automate device management and mobile apps",
    link: "/conversational-ai-platform/addigy-chatbot",
    alt: "Addigy",
  },
  {
    image: g_calendar,
    alt: "g_calendar",
    header: "Google Calendar",
    content: "Automate event scheduling and time management",
    link: "/conversational-ai-platform/google-calendar-chatbot",
  },
  {
    image: gusto,
    alt: "gusto",
    header: "Gusto",
    content: "Automate onboarding, leave management and more",
    link: "/conversational-ai-platform/gusto-chatbot",
  },
  {
    image: pingIdentity,
    alt: "pingIdentity",
    header: "PingIdentity",
    content: "Automate access, user management and more",
    link: "/conversational-ai-platform/ping-identity-chatbot",
  },
  {
    image: jira_desk,
    alt: "jira_desk",
    header: "Jira Service Desk",
    content: "Automate ticketing, approvals and more",
    link: "/conversational-ai-platform/jira-service-desk-chatbot",
  },
  {
    image: idaptive,
    alt: "idaptive",
    header: "Idaptive",
    content: "Automate access, user management and more",
    link: "/conversational-ai-platform/idaptive-chatbot",
  },
  {
    image: halo,
    alt: "halo",
    header: "HaloITSM",
    content: "Automate ticketing, user management and more",
    link: "/conversational-ai-platform/halo-itsm-chatbot",
  },
  {
    image: fingercheck,
    alt: "fingercheck",
    header: "FingerCheck",
    content: "Automate onboarding, absence management and more",
    link: "/conversational-ai-platform/fingercheck-chatbot",
  },
  {
    image: service_desk,
    alt: "ServiceDesk Plus",
    header: "ServiceDesk Plus",
    content: "Automate tickets for user requests, manage users, and more",
    link: "/conversational-ai-platform/servicedesk-plus-chatbot",
  },
  {
    image: tsheets,
    alt: "tsheets",
    header: "TSheets",
    content: "Automate onboarding, leave management and more",
    link: "/conversational-ai-platform/tsheets-chatbot",
  },
  {
    image: otrs,
    alt: "otrs",
    header: "((OTRS)) Community Edition",
    content: "Automate ticketing, user management and more",
    link: "/conversational-ai-platform/otrs-chatbot",
  },
  {
    image: lastpass,
    alt: "lastpass",
    header: "LastPass",
    content: "Automate password management and data security",
    link: "/conversational-ai-platform/lastpass-chatbot",
  },
  {
    image: zoho_desk,
    alt: "zoho_desk",
    header: "Zoho Desk",
    content: "Automate ticketing, user management and more",
    link: "/conversational-ai-platform/zoho-desk-chatbot",
  },
  {
    image: zoho_people,
    alt: "zoho_people",
    header: "Zoho People",
    content: "Automate onboarding, leave management and more",
    link: "/conversational-ai-platform/zoho-people-chatbot",
  },
  {
    image: vonage,
    alt: "vonage",
    header: "Vonage",
    content: "Automate sending business texts to end users",
    link: "/conversational-ai-platform/vonage-chatbot",
  },
  {
    image: purelyhr,
    alt: "PurelyHR",
    header: "PurelyHR",
    content: "Automate time off requests",
    link: "/conversational-ai-platform/purelyhr-chatbot",
  },
  {
    image: peoplehr,
    alt: "peoplehr",
    header: "People HR",
    content: "Automate onboarding, leave entitlement and more",
    link: "/conversational-ai-platform/peoplehr-chatbot",
  },
  {
    image: orangehrm,
    alt: "orangehrm",
    header: "OrangeHRM",
    content: "Automate onboarding workflow",
    link: "/conversational-ai-platform/orange-hrm-chatbot",
  },
  {
    image: onelogin,
    alt: "onelogin",
    header: "OneLogin",
    content: "Automate user, access management and more",
    link: "/conversational-ai-platform/onelogin-chatbot",
  },
  {
    image: icd,
    alt: "icd",
    header: "IBM Control Desk",
    content: "Automate ticketing, requests and more",
    link: "/conversational-ai-platform/ibm-control-desk-chatbot",
  },
  {
    image: deputy,
    alt: "deputy",
    header: "Deputy",
    content: "Automate shift scheduling, onboarding and more",
    link: "/conversational-ai-platform/deputy-chatbot",
  },

  {
    image: bamboo,
    alt: "bamboo",
    header: "BambooHR",
    content: "Automate onboarding, time off, employee benefits and more",
    link: "/conversational-ai-platform/bamboohr-chatbot",
  },
  {
    image: workday,
    alt: "workday",
    header: "Workday",
    content: "Streamline onboarding, manage time off, administer employee benefits & more",
    link: "/conversational-ai-platform/workday-chatbot",
  },
  {
    image: authO,
    alt: "auth0",
    header: "Auth0",
    content: "Automate user management and user requests",
    link: "/conversational-ai-platform/auth0-chatbot",
  },
  {
    image: box,
    alt: "box",
    header: "Box",
    content: "Automate file and folder creation/manipulation workflows",
    link: "/conversational-ai-platform/box-chatbot",
  },
  {
    image: dropbox,
    alt: "Dropbox",
    header: "Dropbox",
    content: "Automate file and folder creation/manipulation workflows",
    link: "/conversational-ai-platform/dropbox-chatbot",
  },
  {
    image: confluence,
    header: "Confluence",
    content:
      "Automate document search and link retrieval workflow for your users",
    link: "/conversational-ai-platform/confluence-chatbot",
    alt: "Confluence",
  },
  // {
  //   image: dropbox,
  //   alt: "Dropbox for Business",
  //   header: "Dropbox for Business",
  //   content: "Automate sending out invitations to a new member of your team. ",
  //   link: "/integration/dropbox-business"
  // },
  {
    image: freshdesk,
    alt: "Freshdesk",
    header: "Freshdesk",
    content: " Automate ticket creation, contact modification and much more",
    link: "/conversational-ai-platform/freshdesk-chatbot",
  },
  {
    image: jsm,
    alt: "jsm",
    header: "JSM",
    content: " Automate ticket creation, contact modification and much more",
    link: "/conversational-ai-platform/jsm-chatbot",
    toDisplayNew: true,
  },
  {
    image: freshservice,
    alt: "freshservice",
    header: "Freshservice",
    content: "Automate ticket creation, requestor modification and much more",
    link: "/conversational-ai-platform/freshservice-chatbot",
  },
  {
    image: alloynav,
    header: "Alloy Navigator",
    content: "Automate ticketing, contact management and more.",
    link: "/conversational-ai-platform/alloy-navigator-chatbot",
    alt: "Alloy Navigator",
  },
  {
    image: hornbill,
    header: "Hornbill",
    content: "Automate ticketing, contact management and more.",
    link: "/conversational-ai-platform/hornbill-chatbot",
    alt: "Hornbill Chatbot",
  },
  {
    image: gdrive,
    alt: "Google Drive",
    header: "Google Drive",
    content:
      "Automate document search and link retrieval workflow for your users",
    link: "/conversational-ai-platform/google-drive-chatbot",
  },
  {
    image: jira,
    alt: "Jira",
    header: "Jira",
    content: "Automate issue management and more",
    link: "/conversational-ai-platform/jira-chatbot",
  },
  {
    image: jumpcloud,
    alt: "JumpCloud",
    header: "JumpCloud",
    content: "Automate adding a user, creating a new group and much more",
    link: "/conversational-ai-platform/jumpcloud-chatbot",
  },
  {
    image: ad,
    alt: "Microsoft Azure AD",
    header: "Microsoft Azure AD",
    content: "Automate account unlocks, password resets and much more",
    link: "/conversational-ai-platform/active-directory-chatbot",
  },
  {
    image: office365,
    alt: "Office 365",
    header: "Office 365",
    content:
      "Automate group creation, adding a new user to group and much more",
    link: "/conversational-ai-platform/office-365-chatbot",
  },
  {
    image: okta,
    alt: "okta",
    header: "Okta",
    content: "Automate adding a new user, creating a new group and much more",
    link: "/conversational-ai-platform/okta-chatbot",
  },
  {
    image: onedrive,
    alt: "onedrive",
    header: "OneDrive",
    content: "Automate file search and link retrieval workflow for your users",
    link: "/conversational-ai-platform/onedrive-chatbot",
  },
  {
    image: outlook,
    alt: "outlook",
    header: "Outlook",
    content: "Automate email messages to end-users",
    link: "/conversational-ai-platform/outlook-chatbot",
  },
  {
    image: gmail,
    alt: "gmail",
    header: "Gmail",
    content: "Automate email messages to end-users",
    link: "/conversational-ai-platform/gmail-chatbot",
  },
  {
    image: servicenow,
    alt: "ServiceNow",
    header: "ServiceNow",
    content: "Automate incident creation, user addition and much more",
    link: "/conversational-ai-platform/servicenow-chatbot",
  },
  {
    image: sharepoint,
    alt: "Sharepoint",
    header: "Sharepoint",
    content:
      "Automate document search and link retrieval workflow for your users",
    link: "/conversational-ai-platform/sharepoint-chatbot",
  },
  {
    image: slack,
    alt: "slack",
    header: "Slack",
    content: "Automate sending messages to Slack channels",
    link: "/integration/slack",
  },
  {
    image: solarwinds,
    alt: "Solarwinds",
    header: "Solarwinds",
    content: "Automate incident creation, user addition and much more.",
    link: "/conversational-ai-platform/solarwinds-chatbot",
  },
  {
    image: twilio,
    alt: "Twilio",
    header: "Twilio",
    content: "Automate OTP generation and verification workflows",
    link: "/conversational-ai-platform/twilio-chatbot",
  },
  {
    image: telnyx,
    header: "Telnyx",
    content: "Automate OTP generation and verification workflows",
    link: "/conversational-ai-platform/telnyx-chatbot",
    alt: "Telnyx",
  },
  {
    image: zendesk,
    alt: "zendesk",
    header: "Zendesk",
    content: "Automate ticket creation, user addition and much more",
    link: "/conversational-ai-platform/zendesk-chatbot",
  },
  {
    image: zenefits,
    alt: "zenefits",
    header: "Zenefits",
    content: "Automate user information retrieval workflow",
    link: "/conversational-ai-platform/zenefits-chatbot",
  },
];
const ldSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Workativ",
  url: "https://workativ.com",
  description:
    " Workativ Assistant is easy to use 'AI + Automation' SaaS product for companies to build an intelligent Virtual Assistant/Chatbot with pre-built automations, to provide employee support, reduce Help Desk costs, and increase productivity. No coding required! ",
  sameAs: [
    "https://www.youtube.com/channel/UCDNRPFuv8tI7XDm117XV9eg",
    "https://twitter.com/workativ",
    "https://www.facebook.com/workativ",
  ],
  sourceOrganization: {
    "@type": "Organization",
    name: "Workativ Software Solutions",
    description:
      " Workativ offers an intelligent AI + Automation platform to build and deploy purpose-built Chatbot for workplace support. ",
    url: "https://www.workativ.com",
    logo: "https://workativ.com/workativ-logo.196a2d6c.png",
    founder: {
      "@type": "Person",
      name: "Imran Quraishy",
    },
    sameAs: ["https://www.linkedin.com/company/workativ-com/"],
  },
};
export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Free Chatbot Integrations with Applications | Explore Chatbot integrations with Zendesk, Office 365, Zenefits, Freshdesk, Okta, JumpCloud and many more"
        description="Integrate Chatbot with over 50+ popular IT, HR and Business Applications and explore the full potential of Conversational AI and Process Automation."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={topImage}
        ogTitle="Free Chatbot Integrations with Applications | Explore Chatbot integrations with Zendesk, Office 365, Zenefits, Freshdesk, Okta, JumpCloud and many more"
        ogDescription="Integrate Chatbot with over 50+ popular IT, HR and Business Applications and explore the full potential of Conversational AI and Process Automation."
        schema={ldSchema}
      />
      <Container>
        <Layout backgroundColor={" bg_slider_home pad_0"} logoFor="ASSISTANT">
          <RegisterSection
            rightImage={topImage}
            backgroundColor={" bg_slider_home"}
            additionalClass={"mobile_image_enable"}
          >
            <RegisterSection.FormWithContent>
              <RegisterSection.MainHeader>
                Automate faster with one-click app integrations
              </RegisterSection.MainHeader>
              <RegisterSection.SubHeader>
                Integrate chatbot with 100+ applications instantly. Tap into your
                app ecosystem to automate workflows from chatbot without any
                coding. You choose, we deliver for you.
              </RegisterSection.SubHeader>
            </RegisterSection.FormWithContent>
          </RegisterSection>
          <ApplicationsList />
          <CardsThree />
          <KeyResources cards={resoucesData} />
          <RequestForm isFooterForm={true} />

          {/* {isSmall ? null : <OnScrollPopup />} */}

          {/* <CookiesPoup />
          <GoToPopup /> */}
        </Layout>
      </Container>
    </>
  );
}

export const sortAnArrayAlphabetically = (array, key) => {
  const sortByFirstItem = sortBy(prop(key));
  //console.log("fff", sortByFirstItem(array));
  return sortByFirstItem(array);
};

function ApplicationsList() {
  const [search, setSearch] = useState("");

  return (
    <section className="Integrations_card">
      <div className="container">
        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12">
          <div className="row">
            <div className="search_marketpalce">
              <input
                className="search_click"
                type="text"
                id="myInput"
                placeholder="Search apps"
                title="Type in a name"
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="search_icon">
                <SearchIcon />
              </span>
            </div>
            <div className="integration_boxes" id="myUL">
              {integrationsPage &&
                sortAnArrayAlphabetically(integrationsPage, "header")
                  .filter(
                    (app) =>
                      app.header.toLowerCase().indexOf(search.toLowerCase()) !=
                      -1
                  )
                  .map((apps) => (
                    <div className="col-md-6 col-lg-3 col-sm-6 col-xs-12 col-12 integrations_cards">
                      <a
                        className="integrations_cards_wrapper"
                        href={apps.link}
                      >
                        <div className="card_head">
                          <img loading="lazy" src={apps.image} alt={apps.alt} />
                          <h5>{apps.header}</h5>
                          <p className="card-text">{apps.content}</p>
                          <div className="card_link">
                            Read more{" "}
                            <span>
                              {" "}
                              <ArrowRightICon />
                            </span>
                          </div>
                          {apps.toDisplayNew != undefined && (
                            <span className="coming_soon">Coming soon</span>
                          )}
                        </div>
                      </a>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const resoucesData = [
  {
    image: blogImg,
    header:
      "Browse through topics on AI, Automation, Chatbots and Workplace support",
    url: "https://blog.workativ.com/",
    link: "Read Blog",
    alt: "blog",
  },
  {
    image: whitepaperImg,
    header:
      "What’s the real value for business in transforming workplace support?",
    url: "/conversational-ai-platform/chatbot-whitepaper",
    link: "Read Whitepaper",
    alt: "whitepaper",
  },
  {
    image: videoImg,
    header:
      "Watch videos to learn about all the capabilities of Workativ Assistant",
    url: "https://www.youtube.com/channel/UCDNRPFuv8tI7XDm117XV9eg",
    link: "Watch Now",
    alt: "videoImg",
  },
];
