import React from "react";
import { Link } from "gatsby";

export const Card = ({
  header,
  content,
  content_1,
  linkAddress,
  backgroundClass,
  image,
  altImage
}) => {
  return (
    <div
      className={`${"col-lg-6 col-md-6 col-sm-6 col-xs-1"} ${backgroundClass}`}
    >
      {" "}
      <div className="benefits-workplace-content cogni_workspace_content">
        <img
          src={image}
          alt={altImage ? altImage : "Workativ for IT Support"}
        />
        <h3 className="font-section-normal-text-medium">{header}</h3>
        <p className="font-section-small-text">{content}</p>
        {content_1 && <p>{content_1}</p>}
        <Link to={linkAddress}>
          Learn more{" "}
          <span className="arrow_svg_link">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="long-arrow-right"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className="svg-inline--fa fa-long-arrow-right fa-w-14 fa-2x"
            >
              <path
                fill="currentColor"
                d="M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z"
                className
              />
            </svg>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default function Cards({ cards, header, paragraph }) {
  return (
    <section className="helpdesk_solutions how-it-works-solutions">
      <div className="suprcharging_benefits" id="numeric">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="main-slider-left">
                {header ? (
                  <h2>{header}</h2>
                ) : (
                  <h2>Use Workativ to auto scale your employee support fast</h2>
                )}
                {paragraph ? <p>{paragraph}</p> : null}
              </div>
              <div className="cards_wrapper_home">
                {cards.map(card => (
                  <Card {...card} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
